import React from "react";
import "./Backbone.css";
export const Backbone = () => {
  return (
    <div id="backBone">
      <div className="franja"></div>
      <div className="franja"></div>
      <div className="franja"></div>
      <div className="franja"></div>
      <div className="franja"></div>
    </div>
  );
};
